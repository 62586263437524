import React from "react";
import {observer} from "mobx-react";
import {Circle75} from "./Cicrle75";
import reportStore from "../../../Stores/Report/ReportStore";

export const BestOrBadCircle = observer((props) => {

    if(props.best)
        return <>
            <div style={{position: 'relative', margin: ' 100px 0 150px 0', width: '100%'}}>
                <Circle75 size={props.bigSize} color={'#4FC1E9'}
                          dataFlag={false} dasharray={
                    `${reportStore.bestGame === null? 75 : parseInt(Math.round(75 * Math.round(reportStore.bestGame.Seconds * 100 / 600) / 100))} 
                    ${reportStore.bestGame === null? 25 : 100 - parseInt(Math.round(75 * Math.round(reportStore.bestGame.Seconds * 100 / 600) / 100))}`
                }
                          strWidth={5} text={reportStore.bestGame === null? "" : reportStore.bestGame.LevelName}
                          time={
                              reportStore.bestGame === null? "0c." : reportStore.bestGame.Seconds / 60 >= 1? `${Math.floor(reportStore.bestGame.Seconds/60)}м.${reportStore.bestGame.Seconds - Math.floor(reportStore.bestGame.Seconds/60) * 60}с.` : `${reportStore.bestGame.Seconds}с.`
                          } percent={
                    reportStore.bestGame === null? 0 : parseInt(Math.round(reportStore.bestGame.Percent*100))
                }/>

                <Circle75 size={props.smallSize} color={'#A0D468'}
                          dataFlag={true} dasharray={
                    `${reportStore.bestGame === null? 75 : parseInt(Math.round(75* reportStore.bestGame.Percent*100 / 100))} 
                    ${reportStore.bestGame === null? 25 : 100 - parseInt(Math.round(75* reportStore.bestGame.Percent*100 / 100))}`
                }
                          strWidth={8} text={reportStore.bestGame === null? "" : reportStore.bestGame.LevelName}
                          time={
                              reportStore.bestGame === null? "0c." : reportStore.bestGame.Seconds / 60 >= 1? `${Math.floor(reportStore.bestGame.Seconds/60)}м.${reportStore.bestGame.Seconds - Math.floor(reportStore.bestGame.Seconds/60) * 60}с.` : `${reportStore.bestGame.Seconds}с.`
                          } percent={
                    reportStore.bestGame === null? 0 : parseInt(Math.round(reportStore.bestGame.Percent*100))
                }/>

            </div>
        </>

    if(!props.best)
        return <>
            <div style={{position: 'relative', margin: ' 100px 0 150px 0', width: '100%'}}>
                <Circle75 size={props.bigSize} color={'#4FC1E9'}
                          dataFlag={false} dasharray={
                    `${reportStore.worseGame === null? 75 : parseInt(Math.round(75 * Math.round(reportStore.worseGame.Seconds * 100 / 600) / 100))} 
                    ${reportStore.worseGame === null? 25 : 100 - parseInt(Math.round(75 * Math.round(reportStore.worseGame.Seconds * 100 / 600) / 100))}`
                }
                          strWidth={5} text={reportStore.worseGame === null? "" : reportStore.worseGame.LevelName}
                          time={
                              reportStore.worseGame === null? "0c." : reportStore.worseGame.Seconds / 60 >= 1? `${Math.floor(reportStore.worseGame.Seconds/60)}м.${reportStore.worseGame.Seconds - Math.floor(reportStore.worseGame.Seconds/60) * 60}с.` : `${reportStore.worseGame.Seconds}с.`
                          } percent={
                    reportStore.worseGame === null? 0 : parseInt(Math.round(reportStore.worseGame.Percent*100))
                }/>

                <Circle75 size={props.smallSize} color={'#A0D468'}
                          dataFlag={true} dasharray={
                    `${reportStore.worseGame === null? 75 : parseInt(Math.round(75* reportStore.worseGame.Percent*100 / 100))} 
                    ${reportStore.worseGame === null? 25 : 100 - parseInt(Math.round(75* reportStore.worseGame.Percent*100 / 100))}`
                }
                          strWidth={8} text={reportStore.worseGame === null? "" : reportStore.worseGame.LevelName}
                          time={
                              reportStore.worseGame === null? "0c." : reportStore.worseGame.Seconds / 60 >= 1? `${Math.floor(reportStore.worseGame.Seconds/60)}м.${reportStore.worseGame.Seconds - Math.floor(reportStore.worseGame.Seconds/60) * 60}с.` : `${reportStore.worseGame.Seconds}с.`
                          } percent={
                    reportStore.worseGame === null? 0 : parseInt(Math.round(reportStore.worseGame.Percent*100))
                }/>

            </div>
        </>
})