import {action, makeAutoObservable, observable} from "mobx";
import teacherDropdownStore from "../../Components/Inputs/TeacherDropdown/teacherDropdownStore";
import {groupStore} from "../../Components/Inputs/ChildGroup/ChildGroupStore";
import modalStore from "../../Components/modal/modalStore";
import React from "react";
import {nameStore} from "../../Components/Inputs/Name/nameStore";
import {surnameStore} from "../../Components/Inputs/Surname/surnameStore";
import {getChildrensGroup} from "../../Request/api/ChildrenGroups/getChildrensGroup";
import {getChildrensInGroup} from "../../Request/api/ChildrenGroups/getChildrensInGroup";
import {createChildrenGroup} from "../../Request/api/ChildrenGroups/createChildrenGroup";
import {getChildInvite} from "../../Request/api/ChildrenGroups/getChildInvite";
import {addChildInGroup} from "../../Request/api/ChildrenGroups/addChildInGroup";
import authStore from "../AuthStore/AuthStore";
import {deleteGroup} from "../../Request/api/ChildrenGroups/deleteGroup";
import {deleteChild} from "../../Request/api/ChildrenGroups/deleteChildren";
import {updateGroupName} from "../../Request/api/ChildrenGroups/ChangeGroupName";
import {updateChildrenInGroup} from "../../Request/api/ChildrenGroups/changeChild";

class ChildGroupsStore {
    @observable offset = 0
    count = 100
    @observable Groups = [];
    @observable groupId = "";
    @observable groupChildId = "";
    @observable GardenPage = true;
    @observable GroupName = "";
    @observable GroupChlid = [];
    @observable disabledGroupButton = 'disabled';
    @observable disabledGroupChangeButton = 'disabled';
    @observable disabledChildButton = 'disabled';
    kindegartenId = null;
    changeFlag = false;
    childrenId = null;
    children = null;
    group = null;

    constructor() {
        makeAutoObservable(this);
    }

    @action async GetGroups(){
        if(authStore.isAuth){
            this.kindegartenId = authStore.userData.CreatedGartenId === 0? null : authStore.userData.CreatedGartenId;

            this.Groups = await getChildrensGroup(this.kindegartenId).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                    return []
                }
            })
        }
    }

    PageSwap(e){
        this.GardenPage = !this.GardenPage;
        e.preventDefault();
        if(!this.GardenPage)
            childGroupsStore.GetChildByGroup();
    }

    @action async GetChildByGroup(){
        if(authStore.userData.CreatedGartenId !== 0)
            this.GroupChlid = await getChildrensInGroup(this.kindegartenId, this.groupChildId, this.offset, this.count).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                    return []
                }
            })
        else
            this.GroupChlid = await getChildrensInGroup(null, this.groupChildId, this.offset, this.count).then(r => {
                if(r !== null && r.type === 'Data'){
                    return r.data
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                    return []
                }
            })
    }

    CheckFormGroupChange = () => {
        if(teacherDropdownStore.teacher !== '' && groupStore.Group !== ''){
            this.disabledGroupButton = ''
        } else {
            this.disabledGroupButton = 'disabled'
        }
    }

    keyDownFormGroup = (e,history) => {
        if(this.disabledGroupButton === '' && e.code === "Enter"  && document.activeElement.tagName !== "BUTTON"
            && teacherDropdownStore.elem !== 'dropdown-item'){
            this.CreateNewGroup(history);
        }
        teacherDropdownStore.elem = ''
    }

    CheckGroupChange = () => {
        if(groupStore.Group === this.group.Name){
            this.disabledGroupChangeButton = 'disabled';
        } else if(groupStore.Group !== ''){
            this.disabledGroupChangeButton = '';
        } else {
            this.disabledGroupChangeButton = 'disabled';
        }
    }

    KeyDownGroupChange = (e,history) => {
        if(this.disabledGroupChangeButton === '' && e.code === "Enter"){
            this.ChangeGroup(history);
        }
    }

    ChangeGroup(history){
        if(this.disabledGroupChangeButton === ''){
            updateGroupName(this.group.GroupId).then(r => {
                if(r !== null && r.type === 'Data'){
                    modalStore.SetModalInfo("", "ГРУППА УСПЕШНО ИЗМЕНЕНА")
                    modalStore.simpleModalOpen();
                    this.GetGroups();
                    teacherDropdownStore.Clear();
                    groupStore.Clear();
                    this.disabledGroupChangeButton = 'disabled';
                    history.push('/groups');
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
        }
    }

    async CreateNewGroup(history){
        if(this.disabledGroupButton === '' && authStore.isAuth){//this.Groups.map(proxy => {return proxy.Name.toLowerCase()})
            if(this.Groups.map(proxy => {return proxy.Name.toLowerCase()}).includes(groupStore.GetGroup)){
                new Promise((resolve) => {
                    setTimeout(() => {resolve();}, 0);
                }).then(r => {
                    history.push('/groups');
                    teacherDropdownStore.Clear();
                    groupStore.Clear();
                    modalStore.SetModalInfo("Группа с таким названим уже существует", "Ошибка")
                    modalStore.simpleModalOpen();
                    this.closeButtonsGroup();
                })
                return null;
            }
            this.kindegartenId = authStore.userData.CreatedGartenId === 0? null : authStore.userData.CreatedGartenId;
            createChildrenGroup(this.kindegartenId).then(r => {
                if(r !== null && r.type === 'Data'){
                    modalStore.SetModalInfo("", "ГРУППА УСПЕШНО СОЗДАНА!")
                    modalStore.simpleModalOpen();
                    this.GetGroups();
                    teacherDropdownStore.Clear();
                    groupStore.Clear();
                    this.disabledGroupButton = 'disabled';
                    history.push('/groups');
                    this.closeButtonsGroup();
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
        }
    }

    @action async GetInviteChild(){
        getChildInvite(this.children.MemberId).then(r => {
            if(r !== null && r.type === 'Data'){
                modalStore.SetModalInfo(
                    <p className={"modal_invite_p simple_modal_component"}>Передайте этот код родителю:<br/> <span className={"modal_invite_span simple_modal_component"}>{r.data.key}</span> <br/> Код действителен до {r.data.ExpiredDate.replace("T", " ").substr(0, r.data.ExpiredDate.lastIndexOf('.'))}</p>
                    , <>
                        <span className="invite_mobile">ИНВАЙТ-КОД</span>
                        <span className="invite_comp">КОД-ПРИГЛАШЕНИЕ</span>
                    </>)
                modalStore.simpleModalOpen();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    @action async DeleteChild(){
        const promiseToModal = new Promise((resolve) => {
            setTimeout(() => {resolve();}, 0);
        }).then(r => {
            modalStore.SetModalInfo('', "УДАЛИТЬ РЕБЁНКА?")
            modalStore.modalCode = true;
            modalStore.simpleModalOpen();
        })
    }

    DeleteChildOk = () => {
        deleteChild(this.groupChildId, this.children.MemberId).then(r => {
            if(r !== null && r.type === 'Data'){
                modalStore.SetModalInfo("", "ВЫ УДАЛИЛИ РЕБЁНКА");
                modalStore.simpleModalOpen();
                this.GetChildByGroup();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    CheckFormChildChange = () => {
        if(nameStore.Name !== '' && surnameStore.Surname !== ''){
            this.disabledChildButton = ''
        } else {
            this.disabledChildButton = 'disabled'
        }
    }

    keyDownFormChild = (e, history) => {
        if(e.code === "Enter"){
            e.preventDefault()
        }
        if(this.disabledChildButton === '' && e.code === "Enter"){
            this.AddChild(history);
        }
    }

    CheckFormChildChangeInGroup = () => {
        if(this.children.Name === nameStore.Name && this.children.Surname === surnameStore.Surname){
            this.disabledChildButton = 'disabled'
        }else if(nameStore.Name !== '' && surnameStore.Surname !== ''){
            this.disabledChildButton = ''
        } else {
            this.disabledChildButton = 'disabled'
        }
    }

    keyDownFormChildChange = (e, history) => {
        if(e.code === "Enter"){
            e.preventDefault()
        }
        if(this.disabledChildButton === '' && e.code === "Enter"){
            this.ChangeChild(history);
        }
    }

    ChangeChild(history){
        if(childGroupsStore.disabledChildButton === ''){
            updateChildrenInGroup(this.groupChildId, this.childrenId).then(r => {

                if(r !== null && r.type === 'Data'){
                    modalStore.SetModalInfo("", "ВЫ ИЗМЕНИЛИ РЕБЁНКА");
                    modalStore.simpleModalOpen();
                    this.GetChildByGroup();
                    this.disabledChildButton = 'disabled';
                    history.push('/groups');
                    this.closeButtonsChild();
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }

            })
        }

    }

    deleteGroup = () => {

        const promiseToModal = new Promise((resolve) => {
            setTimeout(() => {resolve();}, 0);
        }).then(r => {
            modalStore.SetModalInfo('', "УДАЛИТЬ ГРУППУ?")
            modalStore.modalCode = true;
            modalStore.simpleModalOpen();
        })
    }

    deleteGroupOk = () => {
        deleteGroup(this.groupId).then(r => {
            if(r !== null && r.type === 'Data'){
                modalStore.SetModalInfo("", "ВЫ УДАЛИЛИ ГРУППУ");
                modalStore.simpleModalOpen();
                childGroupsStore.GetGroups();
            } else {
                modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                modalStore.simpleModalOpen();
            }
        })
    }

    ChangeTd(children){
        this.children = children;
        this.changeFlag = true;
        this.childrenId = children.MemberId;
    }

    SelectGroup(group){
        this.group = group;
        this.groupChildId = group.GroupId;
        this.groupId = group.GroupId;
        this.GroupName = group.Name;
    }

    closeButtonsGroup(){
        this.group = null;
        this.groupId = null;
        this.GroupName = null;
    }

    closeButtonsChild(){
        this.children = null;
        this.changeFlag = false;
        this.childrenId = null;
    }

    async AddChild(history){

        if(childGroupsStore.disabledChildButton === '')
            addChildInGroup(this.kindegartenId, this.groupChildId).then(r => {
                if(r !== null && r.type === 'Data'){
                    modalStore.SetModalInfo("", "ВЫ ДОБАВИЛИ РЕБЁНКА")
                    modalStore.simpleModalOpen();
                    nameStore.Clear();
                    surnameStore.Clear();
                    this.GetChildByGroup();
                    this.disabledChildButton = 'disabled'
                    history.push('/groups')
                } else {
                    modalStore.SetModalInfo("Произошла ошибка", "Ошибка")
                    modalStore.simpleModalOpen();
                }
            })
    }
}

const childGroupsStore = new ChildGroupsStore();

export default childGroupsStore;