import React from "react";
import {BrowserRouter as Router, Link, Route, Switch, useHistory} from "react-router-dom";
import authStore from '../../Stores/AuthStore/AuthStore'
import {Account1} from "../../Modules/Account/account1";
import {
    RegistrationKindegarten,
    RegistrationParent,
    RegistrationTeacher,
    RegistrationChildren,
    RegistrationMaster,
    RegistrationInvite,
    RegistrationSelect,
    RegistrationQuestChildren,
    RegistrationQuestKindegarten
} from "../../Modules/RegAuth/registration1";
import Auth from "../../Modules/RegAuth/Auth/Auth";
import {Restore1} from "../../Modules/RegAuth/Restore1";
import {ComfirmMail1} from "../../Modules/RegAuth/comfirmMail1";
import {RestorePassword1} from "../../Modules/RegAuth/restorePassword1";
import GameIframe from "../../Modules/GameIframe/gameIframe"
import Header from "../Header/header";
import {Children} from '../../Modules/Children/Children'
import {ChildrenAdd} from "../../Modules/Children/Components/ChildrenAdd";
import {Kindergarten} from '../../Modules/Kindergarten/Kindergarten'
import {Groups} from "../../Modules/Groups/Groups";
import {KindergartenChange} from "../../Modules/KindergartenChange/KindergartenChange";
import loadStore from "../../Stores/LoadStore/loadStore";
import {observer} from "mobx-react";
import MessageModal from "../../Components/modal/messageModal";
import Modal from "../../Components/modal/modal";
import {TermsOfUser} from "../../Modules/TermsOfUser/termsOfUser";
import {privacyPolicy} from "../../Modules/PrivacyPolicy/privacyPolicy";
import {ChildrenInvite} from "../../Modules/Children/Components/ChildrenInvite";
import {SecurityAccount} from "../../Modules/Account/AccountPages/Security";
import {NotificationsAccount} from "../../Modules/Account/AccountPages/Notifications";
import {TeacherAdd} from "../../Modules/Kindergarten/Components/teacherAdd";
import {AddGroup} from "../../Modules/Groups/Components/Group/addGroup";
import {AddGroupChildren} from "../../Modules/Groups/Components/ChildrenGroup/addGroupChildren";
import RegStepInviteAdd from "../../Modules/RegAuth/RegStep/RegStepInviteAdd";
import {TeacherInvite} from "../../Modules/Account/AccountPages/teacherInvite";
import {ChangeTeacher} from "../../Modules/Kindergarten/Components/changeTeacher";
import {ChangeGroup} from "../../Modules/Groups/Components/Group/changeGroup";
import {ChangeGroupChildren} from "../../Modules/Groups/Components/ChildrenGroup/changeChildren";
import {ChildrenChange} from "../../Modules/Children/Components/ChildrenChange";
import {Report} from "../../Modules/Report/Report";
import modalStore from "../../Components/modal/modalStore";
import planetImage from '../../img/planet.png'
import character from '../../img/character.png'
import mainPageGoal1 from '../../img/main-page-goal-1.png'
import mainPageGoal2 from '../../img/main-page-goal-2.png'
import mainPageGoal3 from '../../img/main-page-goal-3.png'

function Main() {

    return(
        <>
            <Router>
              <div>
                  <Header />
                  <div style={{margin: 0, padding: 0}}>
                    <Switch>
                        <Route exact path={'/'} component={ Home } />

                        <Route exact path={'/game'} component={ GameIframe } />

                        <Route exact path={'/registration/invite'} component={RegistrationInvite} />
                        <Route exact path={'/registration/invite/add'} component={RegStepInviteAdd} />
                        <Route exact path={'/registration/master'} component={RegistrationMaster} />
                        <Route exact path={'/registration/select'} component={RegistrationSelect} />
                        <Route exact path={'/registration/parent'} component={RegistrationParent} />
                        <Route exact path={'/registration/teacher'} component={RegistrationTeacher} />
                        <Route exact path={'/registration/children'} component={RegistrationChildren} />
                        <Route exact path={'/registration/kindegarten'} component={RegistrationKindegarten} />
                        <Route exact path={'/registration/question/children'} component={RegistrationQuestChildren} />
                        <Route exact path={'/registration/question/kindegarten'} component={RegistrationQuestKindegarten} />

                        <Route exact path={'/terms'} component={TermsOfUser} />
                        <Route exact path={'/privacy'} component={privacyPolicy} />

                        <Route exact path={'/authorization'} component={Auth} />

                        {/**/}
                        <Route exact path={'/restore'} component={Restore1} />

                        {/**/}
                        <Route exact path={'/confirmEmail'} component={ComfirmMail1} />

                        {/**/}
                        <Route exact path={'/restore_password'} component={RestorePassword1} />

                        <Route exact path={'/account'} component={Account1} />
                        <Route exact path={'/account/security'} component={SecurityAccount} />
                        <Route exact path={'/account/notification'} component={NotificationsAccount} />
                        <Route exact path={'/account/teacher/invite'} component={TeacherInvite} />

                        <Route exact path={'/kindergarten'} component={Kindergarten} />

                        <Route exact path={'/kindergarten/teacher'} component={TeacherAdd} />

                        <Route exact path={'/kindergarten/change'} component={KindergartenChange} />

                        <Route exact path={'/kindergarten/teacher/change'} component={ChangeTeacher} />

                        <Route exact path={'/groups'} component={Groups} />
                        <Route exact path={'/groups/change'} component={ChangeGroup} />
                        <Route exact path={'/groups/add'} component={AddGroup} />
                        <Route exact path={'/groups/children/add'} component={AddGroupChildren} />
                        <Route exact path={'/groups/children/change'} component={ChangeGroupChildren} />

                        <Route exact path={'/children'} component={Children} />
                        <Route exact path={'/children/add'} component={ChildrenAdd} />
                        <Route exact path={'/children/invite'} component={ChildrenInvite} />
                        <Route exact path={'/children/change'} component={ChildrenChange} />


                        <Route exact path={'/report'} component={Report} />

                        <Route path='*' exact={true} component={NotFound} />
                    </Switch>
                  </div>

                    {/*<Question/>*/}
                    <Modal/>
                    <MessageModal/>
                    <OpacityPanel/>
                    <div id="opacity_panel" onClick={() => {
                        document.getElementById('right_open_menu_id').style.display = 'none';
                        document.getElementById('opacity_panel').style.display = 'none';
                    }}/>
                    <div id="opacity_panel_two"/>
              </div>
            </Router>
            <Loader/>
        </>
    );
}

const OpacityPanel = observer(() => {
    let history = useHistory();
    return <>
        <div id="opacity" style={{position: "fixed", top: 0, left: 0, background: "black",
            opacity: '80%', width: '100%', height: '100%', display: 'none', zIndex: 90}}
             onClick={() => {
                 document.getElementById('simple_modal_message').style.display = 'none';
                 document.getElementById('modal_message').style.display = 'none';
                 document.getElementById('opacity').style.display = 'none';
                 modalStore.modalCode = false;
                 Close(history);
             }}
        />
    </>
})

function Close(history){
    if(authStore.isRegistration){
        if(modalStore.EndReg){
            history.push('/authorization');
            modalStore.ModalClose();
        }
    }
}

const NotFound = () => {
    return <div className="main pt_header">
        <p className="greetings">ДАННОЙ СТРАНИЦЫ НЕ СУЩЕСТВУЕТ</p>

        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
        </svg>

        <div className="row">
            <div className="col-md-8 col-sm-12" style={{display: "flex", margin: '0 auto'}}>
                <div className="row" style={{width: '100%'}}>
                    <div className="col-md-12 col-sm-12">
                        <div className="form_group pt-30 ml-md-5 mb-md-10" >
                            <button id={"auth_button"} style={{margin: 0, width: '100%'}} className="buttons button_blue" onClick={() => window.history.back()}>Назад</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const Loader = observer(() => {
    return <>
        {loadStore.load?
            <>
                <div className="loader" style={{zIndex: 1039, position: "fixed", top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</div>
                <div style={{background: "black", position: "fixed", top: 0, left: 0, width: '100%', height: '100%', zIndex: 1038, opacity: '.89'}}/>
            </>
            :
            <></>}
        </>
})

const Home = observer(() => {
  let history = useHistory();
  if (authStore.isAuth) {
    history.push('/account')
  }
    return(<>

        <div>
            <div className="main-page-top-container row">
                <div style={{}} className="main-page-top-left-part col-md-6 col-sm-12">
                    <div style={{'margin-bottom': '37px'}} className="main-page-main-header">Олимпиада по&nbsp;экологии </div>
                    <div style={{'margin-bottom': '37px'}} className="main-page-header-description">
                        Онлайн-путешествие развивает экологическое мышление, закрепляет знания по окружающему миру
                    </div>
                    <div>
                        <div style={{'margin': 0, 'padding-bottom': '37px'}} className="row">
                            <div className="mb-15">
                                <Link to="authorization">
                                    <button style={{'margin-left': 0, 'margin-right': '37px'}} id="select_education" className="buttons button_light_blue_redesigned">
                                        Войти
                                    </button>
                                </Link>
                            </div>

                            <div className="">
                                <Link to="/registration/invite">
                                    <button style={{'margin': '0'}} id="registration_button" className="buttons button_green_redesigned">
                                        Зарегистрироваться
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-page-planet-image-container col-md-6 col-sm-12">
                    <img src={planetImage} alt=""/>
                </div>
            </div>

            <div style={{'background': '#FFFFFF'}}>
                <div style={{'margin-bottom': '24px', 'padding-top': '66px'}} className="main-page-sub-header">Наша цель</div>
                <div style={{'margin-bottom': '90px'}} className="main-page-sub-description">
                    Дать знания дошкольникам и их родителям о базовых понятиях экологического образа жизни
                </div>
                <div style={{'margin-bottom': '96px'}} className="main-page-goals">
                    <div className="main-page-goal-block">
                        <div><img style={{}} src={mainPageGoal1} alt=""/></div>
                        <div style={{'margin-bottom': '19px'}} className="main-page-goal-header">Дата проведения</div>
                        <div className="main-page-goal-description">7 ноября - 12 декабря</div>
                    </div>
                    <div className="main-page-goal-block">
                        <div><img style={{}} src={mainPageGoal2} alt=""/></div>
                        <div style={{'margin-bottom': '19px'}} className="main-page-goal-header">Возраст</div>
                        <div className="main-page-goal-description">5 - 8 лет</div>
                    </div>
                    <div className="main-page-goal-block">
                        <div><img style={{}} src={mainPageGoal3} alt=""/></div>
                        <div style={{'margin-bottom': '19px'}} className="main-page-goal-header">Дипломы</div>
                        <div className="main-page-goal-description">всем участникам</div>
                    </div>
                </div>
                <div style={{'padding-bottom': '47px'}} className="main-page-second-header">Задания в игровой форме</div>
            </div>

            <div className="row main-page-footer">
                <div className="main-page-character-container col-md-6">
                    <img src={character} alt=""/>
                </div>
                <div className="col-md-6">
                    <div style={{'padding-top': '40px'}} className='main-page-footer-title'>Чем полезна олимпиада</div>
                    <div className="main-page-footer-list">
                        <ul>
                            <li>Поддерживает интерес детей к обучению и облегчает усвоение информации</li>
                            <li>Мотивирует детей к бережному отношению к живой и неживой природе</li>
                            <li>Формирует представления о классах отходов и многообразии продуктов для повторного использования после переработки</li>
                            <li>Развивает экологическое сознание, основанное на гуманном и ценностном отношении к природе</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
})


export default Main;