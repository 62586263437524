import request from "../../Request";
import {rThen} from "../../requestThen";
import {emailStore} from "../../../Components/Inputs/Email/EmailStore";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";
import {telephoneStore} from "../../../Components/Inputs/Telephone/TelephoneStore";

export let userRegistration = async () => {

    return request.post(
        `/api/User`,
        {
            email: emailStore.GetEmail,
            password: newPasswordStore.GetPassword,
            phoneNumber: telephoneStore.GetTelephone.replace(/[() -]/ig, "")
        },
        false
    ).then(r => {
        return rThen(r);
    })
}