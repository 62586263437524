import React from 'react';
import { observer } from "mobx-react";
import {regParent, regTeacher} from '../../../Stores/Registration/SecondRegistration';
import 'react-select-search/style.css';
import {useHistory} from "react-router-dom";
import DropdownUIPosition from "../../../Components/Inputs/Position/dropdownPosition";
import CityUI from "../../../Components/Inputs/City/cityUI";
import CountryUI from "../../../Components/Inputs/Country/CountryUI";
import StateUI from "../../../Components/Inputs/State/StateUI";
import RegistrationButtonUI from "../../../Components/Buttons/RegistrationButton/RegistrationButtonUI";
import GenderUI from "../../../Components/Inputs/gender/genderUI";
import NameUI from "../../../Components/Inputs/Name/nameUI";
import SurnameUI from "../../../Components/Inputs/Surname/surnameUI";
import authStore from "../../../Stores/AuthStore/AuthStore";
import {CheckStep} from "../registration1";
import MiddleNameUI from "../../../Components/Inputs/middleName/middleNameUI";

export const RegStep3Teacher = observer( (props) => {
    let history = useHistory();
    if(authStore.isRegistration){
        CheckStep('teacher');
    }

    return <>
        <form onChange={regTeacher.CheckForm} onKeyDown={(e) => regTeacher.keyDownForm(e, history)}>

            <p className="title_greetings">РЕГИСТРАЦИЯ АККАУНТА</p>
            <p className="greetings">ВВЕДИТЕ ДАННЫЕ ПЕДАГОГА</p>

            <div className="rect">
                <svg width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="60" height="5" rx="2.5" fill="#4FC1E9"/>
                </svg>
            </div>

            <div className="row pt-30">
                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10 ml-md-5">
                        <SurnameUI/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10 ">
                        <NameUI/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10 ml-md-5">
                        <MiddleNameUI/>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <GenderUI checkChange={regTeacher.CheckForm}/>
                    </div>
                </div>

                <div className="col-md-6 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <DropdownUIPosition checkChange={regTeacher.CheckForm} disabled={props.disabled}/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <CountryUI checkChange={regTeacher.CheckForm}/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <StateUI checkChange={regTeacher.CheckForm}/>
                    </div>
                </div>

                <div className="col-md-4 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <CityUI/>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className="form_group mr-md-5 ml-md-5 mb-md-10 mr-sm-10 ml-sm-10 mb-sm-10">
                        <RegistrationButtonUI checkUserData={() => regTeacher.CheckUserDataTeacher(history)}/>
                    </div>
                </div>
            </div>
        </form>
        <svg className="step_reg" width="70" height="10" viewBox="0 0 70 10" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <circle cx="65" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
            <circle cx="35" cy="5" r="5" fill="#434A54"/>
            <circle cx="5" cy="5" r="5" fill="#434A54" fillOpacity="0.5"/>
        </svg>
    </>

    return <>
        <form onChange={regTeacher.CheckForm} onKeyDown={(e) => regTeacher.keyDownForm(e, history)}>
            {/*<FioUI/>*/}

            <div className="form-group" style={{margin: "0px"}}>
                <div className="form-row">
                    <div className="col-md-4">
                        <NameUI/>
                    </div>
                    <div className="col-md-4">
                        <SurnameUI/>
                    </div>
                    <div className="col-md-4">
                        <MiddleNameUI/>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <GenderUI checkChange={regTeacher.CheckForm}/>
            </div>

            <div className="form-group" style={{margin: "0px"}}>
                <div className="form-row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <CountryUI checkChange={regTeacher.CheckForm}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <StateUI checkChange={regTeacher.CheckForm}/>
                    </div>
                </div>
            </div>

            <CityUI/>

            <div className="form-group">
                <DropdownUIPosition checkChange={regTeacher.CheckForm} disabled={props.disabled}/>
            </div>

            <RegistrationButtonUI checkUserData={() => regTeacher.CheckUserDataTeacher(history)}/>

        </form>
    </>
})

export default RegStep3Teacher;
