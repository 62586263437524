import React, {useEffect} from "react";
import { observer } from "mobx-react";
import authStore from "../../../Stores/AuthStore/AuthStore";
import securityStore from "../../../Stores/account/Security";
import PasswordUI from "../../../Components/Inputs/Password/PasswordUI";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";
import {oldPasswordStore} from "../../../Components/Inputs/Password/OldPasswordStore";
import {confirmPasswordStore} from "../../../Components/Inputs/Password/ConfirmPasswordStore";
import {Link, useHistory} from "react-router-dom";
import {LeftHeader} from "../../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../../Structure/RightHeader/rightHeader";

export const SecurityAccount = observer( () => {

    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType === "Children"){
        history.push('/account')
    }

    useEffect(() => {
        securityStore.CheckForm();
    })

    return <>

        <>
            <LeftHeader/>
            <RightHeader/>

            <div className="main mt_215 pt_header">
                <p className="title_greetings">
                    <Link to={'/account'} className="grey_text">
                        {authStore.userData.UserType === "Parent"? "АККАУНТ РОДИТЕЛЯ/" : ""}
                        {authStore.userData.UserType === "Pedagog"? "АККАУНТ ПЕДАГОГА/" : ""}
                    </Link>
                    <Link to={'/account/security'} className="blue_text">ЗАЩИТА</Link>
                </p>
                <p className="greetings">ИЗМЕНЕНИЕ ПАРОЛЯ</p>

                <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                </svg>

                <div className="row">
                    <div className="col-md-8" style={{display: "flex", margin: '0 auto'}}>
                        <div className="row" style={{width: '100%'}}>
                            <form style={{width: '100%'}} onChange={securityStore.CheckForm} onKeyDown={(e) => securityStore.keyDownForm(e)}>
                                <div className="col-md-12">
                                    <div className="form_group ml-md-5 mt-md-10 mb-10">
                                        <PasswordUI text={"Старый пароль"} valid={oldPasswordStore.passwordValid}
                                                    password={oldPasswordStore.password}
                                                    func={oldPasswordStore.SetPassword}
                                                    placeholder={'Укажите старый пароль'}
                                                    HelpText={oldPasswordStore.HelpText}
                                                    src={oldPasswordStore.imgEye}
                                                    type={oldPasswordStore.inputType}
                                                    eyeClcik={oldPasswordStore.eyeClcik}
                                                    styleDisplay={'none'}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form_group ml-md-5 mt-md-10 mb-10">
                                        <PasswordUI text={"Новый пароль"} valid={newPasswordStore.passwordValid}
                                                    password={newPasswordStore.password}
                                                    func={newPasswordStore.SetPassword}
                                                    placeholder={'Укажите новый пароль'}
                                                    HelpText={newPasswordStore.HelpText}
                                                    src={newPasswordStore.imgEye}
                                                    type={newPasswordStore.inputType}
                                                    eyeClcik={newPasswordStore.eyeClcik}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form_group ml-md-5 mt-md-10 mb-10">
                                        <PasswordUI text={"Подтвердите пароль"} valid={confirmPasswordStore.passwordValid}
                                                    password={confirmPasswordStore.password}
                                                    func={confirmPasswordStore.SetPassword}
                                                    placeholder={'Подтвердите пароль'}
                                                    HelpText={confirmPasswordStore.HelpText}
                                                    src={confirmPasswordStore.imgEye}
                                                    type={confirmPasswordStore.inputType}
                                                    eyeClcik={confirmPasswordStore.eyeClcik}
                                                    styleDisplay={'none'}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form_group ml-md-5 mt-md-10 mb-md-10">
                                        <button style={{width: '100%', margin: 0}} id="registration_button" className={`buttons button_blue ${securityStore.disabledButton}`} type="button" onClick={() => securityStore.CheckData()}>Сохранить</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    </>
})