import {observer} from "mobx-react";
import React from "react";

export const BigTextAndSvg = observer((props) => {
    return <>
        <div style={{display: "flex", margin: props.divM}}>
            <p style={{textAlign: "center", margin: props.pM}} >{props.text}</p>
        </div>


        <div style={{display: "flex", margin: props.div2M}}>
            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>
        </div>
    </>
})