import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {LeftHeader} from "../../Structure/LeftHeader/leftHeader";
import {RightHeader} from "../../Structure/RightHeader/rightHeader";
import {Link, useHistory} from "react-router-dom";
import reportStore from "../../Stores/Report/ReportStore";
import {ChildSelectorDashboard} from "./Components/ChildSelector";
import {Dashboard} from "./Components/Dashboard";
import authStore from "../../Stores/AuthStore/AuthStore";

export const Report = (() => {
    useEffect(() => {
        reportStore.GetChldrensList();
    })
    return <Page/>
})

const Page = observer(() => {
    let history = useHistory();

    if (!authStore.isAuth) {
        history.push('/')
        return null
    }

    if(authStore.decodeJwt.userType !== "Parent"){
        history.push('/account')
    }

    return <>
        <LeftHeader/>
        <RightHeader/>

        <div className="main mt_215 pt_header">

            <p className="title_greetings">
                <Link to={'/account'} className="grey_text">АККАУНТ РОДИТЕЛЯ/</Link>
                <Link to={'/report'} className="blue_text">ОТЧЕТЫ</Link>
            </p>

            <div className="row content_margin">

                <div className="col-sm-12">
                    <div>
                        <p style={{margin: 0, padding: 0}}>ВЫБЕРИТЕ РЕБЁНКА</p>
                        <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
                        </svg>
                    </div>
                </div>

                <div className="col-sm-12">
                    <ChildSelectorDashboard/>
                </div>

                {reportStore.ChildSelectId === null?
                <></> :
                <div className="col-sm-12">
                    <Dashboard/>
                </div>}

            </div>
        </div>
    </>
})