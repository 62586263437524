import {observer} from "mobx-react";
import React from "react";

export const SmallTextAndSvg = observer((props) => {
    return <>
        <div style={{display: "flex", margin: '30px auto 0 auto'}}>
            {props.text}
        </div>

        <div style={{display: "flex", margin: '10px auto 30px auto'}}>
            <svg width="54" height="4" viewBox="0 0 54 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2C0 0.895431 0.895431 0 2 0H52C53.1046 0 54 0.895431 54 2C54 3.10457 53.1046 4 52 4H2C0.895429 4 0 3.10457 0 2Z" fill="#4FC1E9"/>
            </svg>
        </div>
    </>
})