import React from 'react'
import AuthForm from './AuthForm'
import authStore from '../../../Stores/AuthStore/AuthStore'
import {Link, useHistory} from "react-router-dom"
import { observer } from "mobx-react"
import {emailStore} from "../../../Components/Inputs/Email/EmailStore";
import {newPasswordStore} from "../../../Components/Inputs/Password/NewPasswordStore";

const Auth = observer( () => {
  let history = useHistory();
  if (authStore.isAuth) {
    history.push('/')
  }
  let errorText
  if (authStore.errorText != null){
    errorText = <div className="alert alert-danger" role="alert" style={{color: 'red'}}>
                    { authStore.errorText }
                </div>
  }

  emailStore.Clear();
  newPasswordStore.Clear();

  return <>
    <div className="main mt_215 pt_header">
      <p className="title_greetings">АВТОРИЗАЦИЯ</p>
      <p className="greetings">ВВЕДИТЕ ДАННЫЕ АККАУНТА</p>

      <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
      </svg>

      <AuthForm />
      {errorText}
      <Link to="/registration/invite" className="link" onClick={(() => sessionStorage.setItem('step', 'invite'))}>Зарегистрировать новый аккаунт</Link>
    </div>
  </>

  return <>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card shadow-lg rounded-lg mt-5">
                <div className="card-header justify-content-center">
                  <h3 className="font-weight-light my-4">Войти в аккаунт</h3>
                </div>
                <div className="card-body">
                  <p style={{color: "red", display: (authStore.localStoreBlock? "" : "none")}}>
                    <u onClick={() => window.open('https://yandex.ru/support/common/browsers-settings/browsers-cookies.html')}
                    style={{cursor: 'pointer'}}
                    >Включите cookie</u>, чтобы пользоваться сайтом.</p>
                  <AuthForm />
                </div>
                {errorText}
                <div className="card-footer text-center">
                  <div className="small">
                    <Link to="/registration/invite" className="doc_link" onClick={(() => sessionStorage.setItem('step', 'invite'))}>Зарегистрировать новый аккаунт</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
})

export default Auth