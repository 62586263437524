import React from 'react';
import { observer } from "mobx-react";
import modalStore from "./modalStore";
import gardenStore from "../../Stores/Garden/GardenStore";
import childGroupsStore from "../../Stores/ChildGroups/ChildGroupsStore";
import {childrenStore} from "../../Stores/Children/Children";

export const MessageModal = observer( () => {

    return <>
        <div className="simple_modal_component" id="simple_modal_message" style={{display: "none", position: "fixed", top: '50%', left: '50%', transform: 'translate(-50%, -50%)', flexDirection: "column",
            background: "#F5F7FA", borderRadius: '5px', zIndex: 999}}>
            {modalStore.modalCode?
            <>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around"}}>
                    <div className="simple_modal_component modal_title" style={{margin: 0, padding: '12px 38px 0 38px', fontSize: '36px', textAlign: "center"}}>{modalStore.type}</div>
                </div>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around", margin: '10px 0 20px 0'}}>
                    <svg className="simple_modal_component" width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className="simple_modal_component" width="60" height="5" rx="2.5" fill="#4FC1E9"/>
                    </svg>
                </div>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around"}}>
                    <p className="simple_modal_component" style={{margin: '0 0 20px 0', padding: 0, fontSize: '14px', color: "rgba(51, 51, 51, 0.5)"}}>{modalStore.message}</p>
                </div>
                <div style={{display: "flex"}}>
                    <div className="row" style={{width: '100%'}}>
                        <div className="col-md-6 col-sm-12">
                            <div className="form_row simple_modal_component" style={{display: "flex", justifyContent: "space-around"}}>
                                <button id="select_education" className={'buttons'} style={{width: '100%', margin: '0 20px 14px 20px', maxWidth: '200px'}} onClick={() => {
                                    document.getElementById('simple_modal_message').style.display = 'none';
                                    document.getElementById('opacity').style.display = 'none';
                                    modalStore.modalCode = false;
                                    if(window.location.href.indexOf('/groups') !== -1 && childGroupsStore.GardenPage){

                                        childGroupsStore.deleteGroupOk();
                                    } else if(window.location.href.indexOf('/groups') !== -1 && !childGroupsStore.GardenPage){

                                        childGroupsStore.DeleteChildOk();
                                    } else if(window.location.href.indexOf('/kindergarten') !== -1){
                                        gardenStore.deletePedagogOk();
                                    } else if(window.location.href.indexOf('/children') !== -1){
                                        childrenStore.deleteChildrenOk();
                                    } else {}
                                }}>
                                    ДА
                                </button>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form_row" style={{display: "flex", justifyContent: "space-around"}}>
                                <button id="registration_button" className={'buttons'} style={{width: '100%', margin: '0 20px 14px 20px', maxWidth: '200px'}} onClick={() => {
                                    document.getElementById('simple_modal_message').style.display = 'none';
                                    document.getElementById('opacity').style.display = 'none';
                                }}>
                                    НЕТ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around"}}>
                    <p className="simple_modal_component modal_title text_modal_top" style={{margin: 0, padding: '12px 38px 0 38px'}}>{modalStore.type}</p>
                </div>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around", margin: '10px 0 25px 0'}}>
                    <svg className="simple_modal_component" width="60" height="5" viewBox="0 0 60 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className="simple_modal_component" width="60" height="5" rx="2.5" fill="#4FC1E9"/>
                    </svg>
                </div>
                <div className="simple_modal_component" style={{display: "flex", justifyContent: "space-around"}}>
                    <div className="simple_modal_component" style={{margin: '0 0 20px 0', padding: '0 10px', fontSize: '14px', color: "rgba(51, 51, 51, 0.5)"}}>{modalStore.message}</div>
                </div>
                <div style={{display: "flex"}}>
                    <button id="registration_button" className={'buttons button_blue'} style={{width: '100%', margin: '0 38px 14px 38px'}} onClick={() => {
                        document.getElementById('simple_modal_message').style.display = 'none';
                        document.getElementById('opacity').style.display = 'none';
                    }}>
                        Закрыть
                    </button>
                </div>
            </>
            }
        </div>
    </>
})

export default MessageModal;