import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/dropdown.css';
import './styles/mainStyle.css';
import './Modules/RegAuth/RegStep/dropdown.css';
import Main from './Structure/Main/main';
import {
  isLegacyEdge,
  isIE,
} from "react-device-detect";


import { configure } from "mobx"
import authStore from "./Stores/AuthStore/AuthStore";
import Notify from "./Notifications/Notify";
import './styles/style.css'
import {Footer} from "./Structure/Footer/footer";

configure({
    enforceActions: "never",
})

if (!isLegacyEdge && !isIE) {
  ReactDOM.render(
    <React.StrictMode>
      <div className="container">
        <Main />
      </div>
      <div>
          <div className="container">
              <Footer/>
          </div>
      </div>
    </React.StrictMode>,
    document.getElementById('page_content')
  );
} else {
  window.location.href = "/old_browser.html";
}

function authInOtherPageSession(){

    return (
        (
            get_cookie('sessionAccess') !== null && get_cookie('sessionRefresh') !== null
            &&
            sessionStorage.getItem('access') === null && sessionStorage.getItem('refresh') === null
            &&
            localStorage.getItem('access') === null && localStorage.getItem('refresh') === null
        )
        ||
        (
            get_cookie('sessionAccess') === null && get_cookie('sessionRefresh') === null
            &&
            sessionStorage.getItem('access') === null && sessionStorage.getItem('refresh') === null
            &&
            localStorage.getItem('access') !== null && localStorage.getItem('refresh') !== null
        )
    )
}

window.onload = () => {
    if(authInOtherPageSession()){
        authStore.SetAuthDataWithoutThen({access: get_cookie('sessionAccess'),
                                               refresh: get_cookie('sessionRefresh'),
                                               error: null}
                                        )
        Notify.connectToNotify();
    }
}

export function get_cookie (cookieName){
    let results = document.cookie.match ( `(^|;) ?${cookieName}=([^;]*)(;|$)` );

    if ( results ){
        return ( unescape ( results[2] ) );
    }
    else{
        return null;
    }
}

window.addEventListener('storage', function(e){


    setTimeout(() => {
        if(e.key === "auth" && e.newValue === "true" && authInOtherPageSession()){
            authStore.SetAuthDataWithoutThen({access: get_cookie('sessionAccess') || localStorage.getItem('access'),
                refresh: get_cookie('sessionRefresh') || localStorage.getItem('refresh'),
                error: null}
            )
            Notify.connectToNotify();
        } else if(e.key === "logout" && e.newValue === "true"){
            authStore.Logout();
            window.location.href = '/authorization';

        }
    }, 1)

})