import React from 'react';
import { observer } from "mobx-react";
import restorePassword from "../../Stores/Registration/restorePassword";
import EmailUI from "../../Components/Inputs/Email/EmailUI";

export const Restore1 = observer( () => {

    return <>

        <div className="main pt_header">
            <p className="title_greetings">ЗАБЫЛИ ПАРОЛЬ?</p>
            <p className="greetings">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</p>

            <svg width="90" height="5" viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="90" height="5" rx="2.5" fill="#4FC1E9"/>
            </svg>

            <div className="row">
                <div className="col-md-8 col-sm-10" style={{display: "flex", margin: '0 auto'}}>
                    <div className="row" style={{width: '100%'}}>
                        <form style={{width: '100%'}}>
                            <div className="col-md-12 col-sm-12">
                                <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10 mt-sm-5">
                                    <EmailUI/>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                <div className="form_group ml-md-5 mt-md-10 mb-md-10 mb-sm-10">
                                    <button type="button" id="auth_button" style={{width: '100%', margin: 0}} className="buttons button_blue"
                                            onClick={() => restorePassword.checkRestoreData()}>
                                        Отправить
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </>
})