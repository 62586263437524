import {observer} from "mobx-react";

export const Footer = observer(() => {
    return <div className={"footer"}>
        <div className={"footer_left"}>
            <p style={{cursor: "pointer"}} onClick={() => {window.open('https://playstand.ru')}}>Наш сайт: playstand.ru</p>
        </div>
        <div className={"footer_right"}>
            <div className="footer_img">
                <IconSocial size={'40px'} src={
                    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 215">
                        <g id="VK">
                            <circle id="Background" cx="107.5" cy="107.5" r="107.5"/>
                            <path id="VK-2" data-name="VK" className="cls-1" style={{fill: '#fff'}}
                                  d="M111.44,147.14C69.76,147.14,46,118.56,45,71H65.87c.69,34.9,16.08,49.69,28.27,52.74V71h19.67v30.1c12-1.29,24.69-15,28.95-30.1h19.67c-3.28,18.59-17,32.31-26.75,37.95A60.16,60.16,0,0,1,167,147.14H145.36c-4.65-14.48-16.24-25.68-31.55-27.2v27.2Z"/>
                        </g>
                    </svg>
                } link={'https://vk.com/innovationsforkids'}/>
                <IconSocial size={'40px'} src={
                    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 215">
                        <g id="Ok">
                            <circle id="Background" cx="107.5" cy="107.5" r="107.5"/>
                            <g id="Ok-2" data-name="Ok">
                                <path id="Ok-3" data-name="Ok" className="cls-1" style={{fill: '#fff'}}
                                      d="M938.45,664.9a29.82,29.82,0,1,0-29.81-29.82,29.86,29.86,0,0,0,29.81,29.82m0-42.18a12.37,12.37,0,1,1-12.36,12.36,12.37,12.37,0,0,1,12.36-12.36"
                                      transform="translate(-831 -554)"/>
                                <path id="Ok-4" data-name="Ok" className="cls-1" style={{fill: '#fff'}}
                                      d="M950.54,689.17a55.77,55.77,0,0,0,17.35-7.2,8.77,8.77,0,1,0-9.32-14.86,37.93,37.93,0,0,1-40.14,0A8.77,8.77,0,1,0,909.1,682a55,55,0,0,0,17.35,7.2l-16.7,16.7a8.74,8.74,0,0,0,0,12.37,9.07,9.07,0,0,0,6.18,2.59,8.5,8.5,0,0,0,6.19-2.59l16.43-16.43L955,718.24a8.75,8.75,0,0,0,12.37-12.37Z"
                                      transform="translate(-831 -554)"/>
                            </g>
                        </g>
                    </svg>
                } link={'https://ok.ru/innovationsforkids'}/>
                <IconSocial size={'40px'} src={
                    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 215">
                        <g id="YouTube">
                            <circle id="Background" cx="107.5" cy="107.5" r="107.5"/>
                            <path id="YouTube-2" data-name="YouTube" className="cls-1" style={{fill: '#fff'}}
                                  d="M1545.53,357.18a13.94,13.94,0,0,0-9.85-9.85C1527,345,1492.15,345,1492.15,345s-34.85,0-43.54,2.33a14,14,0,0,0-9.85,9.85c-2.33,8.69-2.33,26.82-2.33,26.82s0,18.13,2.33,26.82a14,14,0,0,0,9.85,9.85c8.69,2.33,43.54,2.33,43.54,2.33s34.84,0,43.53-2.33a13.94,13.94,0,0,0,9.85-9.85c2.33-8.69,2.33-26.82,2.33-26.82S1547.86,365.87,1545.53,357.18ZM1481,400.71V367.29L1510,384Z"
                                  transform="translate(-1385 -277)"/>
                        </g>
                    </svg>
                } link={'https://www.youtube.com/user/StandUpProject74'}/>
            </div>
            <div className="footer_contact_data">
                <a href={'tel:8 800 101 19 74'}>8 800 101 19 74</a>
                <a href={'mailto:olymp@playstand.ru'}>olymp@playstand.ru</a>
            </div>
        </div>
    </div>
})

const IconSocial = ((props) => {
    return <div style={{width: props.size, height: props.size, cursor: "pointer"}} onClick={() => {window.open(props.link)}}>
        {props.src}
    </div>
})
