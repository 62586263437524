const ports = ["51350", "5000"];

const random = Math.floor(Math.random() * ports.length);
var port = ports[random];
port = 51350;


export const appConfig = {

  api_host: "https://api.playstand.ru",
  notify_host: "https://notify.playstand.ru",
  // api_host: "https://api.playstand.ru",
  // notify_host: "https://notify.playstand.ru",


  // api_host: process.env.DEVELOPMENT_MODE === "true" ? "https://api.serviceplaystand.ru" : "https://api.playstand.ru"

  // api_host: process.env.DEVELOPMENT_MODE === "true" ? "http://localhost:"+port : "http://localhost:"+port
}
